import {combineReducers} from 'redux';
import carsReducer from './carsReducer';
import citiesReducer from './citiesReducer';
import callbackFormReducer from './callbackFormReducer';
import dealersReducer from './dealersReducer';
import resultFormReducer from './resultFormReducer';

const selectedCarReducer = (selectedCar = null, action) => {
    if (action.type === 'CAR_SELECTED') {
        return action.payload;
    }

    return selectedCar;
};

const selectedCityReducer = (selectedCity = null, action) => {
    if (action.type === 'CITY_SELECTED') {
        return action.payload;
    }

    return selectedCity;
};

const selectPageReducer = (currentPage = 0, action) => {
    if (action.type === 'PAGE_SELECTED') {
        return action.payload;
    }

    return currentPage;
};

const activePageReducer = (activePage = 1, action) => {
    if (action.type === 'ACTIVE_PAGE') {
        return action.payload;
    }

    return activePage;
};

const selectIndexReducer = (currentIndex = 0, action) => {
    if (action.type === 'INDEX_SELECTED') {
        return action.payload;
    }

    return currentIndex;
};

const selectDateReducer = (currentDate = new Date(), action) => {
    if (action.type === 'DATE_SELECTED') {
        return action.payload;
    }

    return currentDate;
};
const selectTimeReducer = (currentTime = null, action) => {
    if (action.type === 'TIME_SELECTED') {
        return action.payload;
    }

    return currentTime;
};

const selectCoordsReducer = (currentCoords = [], action) => {
    if (action.type === 'CORDS_SELECTED') {
        return action.payload;
    }

    return currentCoords;
};

const selectDealerReducer = (dealer = null, action) => {
    if (action.type === 'DEALER_SELECTED') {
        return action.payload;
    }

    return dealer;
};

const selectUrlParamReducer = (urlParam = '', action) => {
    if (action.type === 'URL_PARAM_SELECTED') {
        return action.payload;
    }

    return urlParam;
};




export default combineReducers({
    cars: carsReducer,
    selectedCar: selectedCarReducer,
    cities: citiesReducer,
    selectedCity: selectedCityReducer,
    dealers: dealersReducer,
    callbackForm: callbackFormReducer,
    resultForm: resultFormReducer,
    selectedPage: selectPageReducer,
    activePage: activePageReducer,
    selectedIndex: selectIndexReducer,
    selectedDate: selectDateReducer,
    selectedTime: selectTimeReducer,
    selectedCoords: selectCoordsReducer,
    selectedDealer: selectDealerReducer,
    selectedUrlParamReducer: selectUrlParamReducer
});