import React, {Suspense} from 'react';
import './App.css';

const Layout = React.lazy(() => import('./pages/Layout'));

const App = () => (
    <div>
        <Suspense fallback={<div>Loading...</div>}>
            <Layout/>
        </Suspense>
    </div>
);


export default App;
